import React from 'react'
import Fade from 'react-reveal/Fade'

import './partners.scss'
import { Link } from 'gatsby'

const Partners = ({ ...props }) => {
    function createMarkup() {
        return {__html: props.content}
    }

    return(
        <div className="partners">
            <div className="partners__container">
                <div className="partners__column">
                    <h3>Nossos <em>parceiros</em></h3>

                    <div className="columns-services__content"
                        dangerouslySetInnerHTML={createMarkup()} />

                    <div className="partners__link">
                        <Link to="/">Seja um parceiro</Link>
                    </div>
                </div>

                <Fade bottom cascade>
                    <div className="partners__logos">
                        { props.data.map((item, index) => (
                            <div className="logo" key={ index }>
                                <a href={ item.link_do_parceiro } target="_blank" rel="noreferrer">
                                    <img
                                        src={ item.logo_do_parceiro.localFile.childImageSharp.original.src }
                                        alt={ item.nome_do_parceiro } />
                                </a>
                            </div>
                        ))}
                    </div>
                </Fade>
            </div>
        </div>
    )
}

export default Partners
