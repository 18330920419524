import React from 'react'
import Fade from 'react-reveal/Fade'
import propTypes from 'prop-types'
import SpaceSlider from './space_slider'

import './space.scss'

const Space = ({ ...props }) => {
    function createMarkup() {
        return {__html: props.content}
    }

    return(
        <div id="nosso-espaco" className="space">
            <Fade bottom cascade>
                <div className="space__container">
                    <h3>Nosso <em>espaço</em></h3>

                    <div className="space__columns">
                        <div className="space__slider">
                            <SpaceSlider data={ props.slider } />
                        </div>

                        <div className="space__content"
                            dangerouslySetInnerHTML={createMarkup()} />
                    </div>
                </div>
            </Fade>       
        </div>
    )
}

Space.propTypes = {
    slider: propTypes.array,
    content: propTypes.string
}

export default Space
