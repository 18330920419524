import React from 'react'
import Fade from 'react-reveal/Fade'
import propTypes from 'prop-types'

import './content-image.scss'

const ContentImage = ({ ...props }) => {
    function createMarkup() {
        return {__html: props.content}
    }

    return(
        <div className="content-image">
            <div className="content-image__container">
                <div className="content-image__content"
                    dangerouslySetInnerHTML={createMarkup()} />
                
                <Fade bottom cascade>
                    <div className="content-image__image">
                        <figure>
                            <div><img src={ props.image } alt={ props.legend } /></div>
                            <figcaption>{ props.legend }</figcaption>
                        </figure>
                    </div>
                </Fade>
            </div>
        </div>
    )
}

ContentImage.propTypes = {
    content: propTypes.string,
    image: propTypes.string,
    legend: propTypes.string
}

export default ContentImage
