import React from 'react'
import Fade from 'react-reveal/Fade'

import './team.scss'

const Team = ({ ...props }) => {
    return(
        <div id="nossa-equipe" className="team">
            <div className="team__container">
                <h3>Nossa <em>equipe</em></h3>

                <Fade bottom cascade>
                    <div className="team__columns">
                    { props.data.map((item) => (
                        <div className="team__column" key={item.directusId}>
                        <figure>
                            <img src={ item.imagem.localFile.childImageSharp.original.src } alt={ item.nome } />
                        </figure>

                        <h4>{ item.nome }</h4>
                        <p>{ item.profissao }</p>
                        { item.crp && 
                            <p>CRP { item.crp }</p>
                        }
                        <div className="team__description">
                            { item.mini_curriculo }
                        </div>

                        { item.instagram && 
                            <p className="team__link"><a href={ item.instagram }>Instagram</a></p>
                        }
                        
                        { item.linkedin && 
                            <p className="team__link"><a href={ item.linkedin }>Linkedin</a></p>
                        }
                        </div>
                    )) }
                    </div>
                </Fade>
            </div>
        </div>
    )
}

export default Team
