import React from 'react'
import Slider from 'react-slick'

import './slider.scss'

const SpaceSlider = ({ data }) => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true
    };

    return(
        <Slider {...settings}>
            { data.map((item, index) => (
                <div key={ index }>
                    <img src={ item.localFile.childImageSharp.fixed.src } alt="Espaço Grupo Reinserir" />
                </div>
            ))}
        </Slider>
    )
}

export default SpaceSlider
