import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'

import MainTitle from '../components/MainTitle'
import ContentImage from '../components/ContentImage'
import Team from '../components/Team'
import Space from '../components/Space'
import Partners from '../components/Partners'


const QuemSomos = ({ data }) => (
  <Layout>
    <SEO title="Quem Somos" />

    <MainTitle
        content={ data.directusQuemSomo.titulo_principal }
        pageClass="pageAbout" />

    <ContentImage
      content={ data.directusQuemSomo.conteudo }
      image={ data.directusQuemSomo.socios_imagem.localFile.childImageSharp.original.src }
      legend={ data.directusQuemSomo.imagem_legenda } />

    <Team data={ data.allDirectusEquipe.nodes } />

    <Space
      slider={ data.directusQuemSomo.imagens_do_espaco }
      content={ data.directusQuemSomo.nosso_espaco }  />

    <Partners
      data={ data.allDirectusParceiro.nodes }
      content={ data.directusQuemSomo.parceiros }
    />

  </Layout>
)

export const data = graphql`
  {
    directusQuemSomo {
      titulo_principal
      conteudo
      socios_imagem {
        localFile {
          childImageSharp {
            original {
              src
            }
          }
        }
      }
      imagem_legenda
      nosso_espaco
      imagens_do_espaco {
        localFile {
          childImageSharp {
            fixed(height: 500, width: 630, cropFocus: CENTER) {
              src
            }
          }
        }
      }
      parceiros
    }
    allDirectusEquipe {
      nodes {
        nome
        profissao
        crp
        mini_curriculo
        instagram
        linkedin
        directusId
        imagem {
          localFile {
            childImageSharp {
              original {
                src
              }
            }
          }
        }
      }
    }
    allDirectusParceiro {
      nodes {
        nome_do_parceiro
        link_do_parceiro
        logo_do_parceiro {
          localFile {
            childImageSharp {
              original {
                src
              }
            }
          }
        }
      }
    }
  }
`


export default QuemSomos
